<template>
  <div class="inline-svg-container">
    <img
      :id="id"
      class="inline-svg-source"
      :src="placeholder"
      :alt="alt"
      :style="
        !isAlreadyInlineSvg
          ? { visibility: 'hidden' }
          : { visibility: 'inherit' }
      "
      :width="width"
      :height="height"
      @load="onload($event, $el)"
    />
    <button
      v-if="showPlayPauseButton() && animationActive"
      aria-label="Animationen anhalten"
      class="play-pause"
      @click.stop="playPauseAnimation"
    >
      <Icon name="pause" alt-text="Animationen pausieren" height="35" />
    </button>
    <button
      v-if="showPlayPauseButton() && !animationActive"
      aria-label="Animationen starten"
      class="play-pause"
      @click.stop="playPauseAnimation"
    >
      <Icon name="play" alt-text="Animationen starten" height="35" />
    </button>
  </div>
</template>

<script>
import { waitForElem } from 'src/lib/helpers'
import Icon from '../icon/icon.vue'
import placeholder from './resources/placeholder.svg'

const generateRandomPart = () => {
  return Math.round(Math.random() * 1000)
}

const randomNumberRegex = /\$\{random\(([0-9]*), *([0-9]*)\)}/

export default {
  components: { Icon },
  inject: ['$http'],
  props: {
    src: {
      type: String,
      required: true
    },
    animateElements: {
      type: Array,
      default: () => []
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: undefined
    },
    height: {
      type: [String, Number],
      default: undefined
    },
    disableWebp: {
      type: Boolean,
      default: false
    },
    disablePlayPause: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    const randomPart1 = generateRandomPart()
    const randomPart2 = generateRandomPart()
    const randomPart3 = generateRandomPart()

    return {
      id: `svg-stage-${randomPart1}-${randomPart2}-${randomPart3}`,
      animationActive: true,
      testing: window.__STORYBOOK_ADDONS || window.Cypress ? true : false,
      isAlreadyInlineSvg: false,
      placeholder
    }
  },
  mounted() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

    this.animationActive = !mediaQuery.matches
    mediaQuery.addEventListener('change', () => {
      this.animationActive = !mediaQuery.matches
      if (this.animationActive) {
        this.startElementAnimations()
      } else {
        this.stopElementAnimations()
      }
    })
    window.addEventListener('blur', this.stopElementAnimations)
    window.addEventListener(
      'focus',
      this.startElementAnimationsIfAnimationsActive
    )
  },
  unmounted() {
    window.removeEventListener('blur', this.stopElementAnimations)
    window.removeEventListener(
      'focus',
      this.startElementAnimationsIfAnimationsActive
    )
  },
  methods: {
    setAttributesOnElement(attributes, element) {
      attributes.forEach(attribute => {
        element.setAttribute(attribute.name, attribute.value)
      })
    },
    onload(event, element) {
      const vDataRegex = /^data-v/
      const parser = new DOMParser()

      Promise.all([
        this.$http.get(this.src).then(response => response.data),
        waitForElem(`#${this.id}`, 2000)
      ]).then(([data, imgElement]) => {
        const svgDocument = parser.parseFromString(data, 'image/svg+xml')
        const svgElement = svgDocument.getElementsByTagName('svg')[0]
        const vueAttributes = [...element.attributes].filter(attribute =>
          vDataRegex.test(attribute.name)
        )

        this.setAttributesOnElement(vueAttributes, svgElement)
        svgElement.setAttribute('class', 'inline-svg')
        svgElement.setAttribute('preserveAspectRatio', 'xMidYMid slice')
        // Remove any invalid XML tags as per http:validator.w3.org
        if (this.id !== '') {
          svgElement.setAttribute('id', this.id)
        }

        if (this.width) {
          svgElement.setAttribute('width', this.width)
        }

        if (this.height) {
          svgElement.setAttribute('height', this.height)
        }

        this.animateElements.forEach(elementAnimationDefinition => {
          const animatedSvgElements = svgDocument.querySelectorAll(
            elementAnimationDefinition.query
          )

          animatedSvgElements.forEach(animatedSvgElement => {
            this.setAttributesOnElement(vueAttributes, animatedSvgElement)
          })
        })
        svgElement.removeAttribute('xmlns:a')
        element.replaceChild(svgElement, imgElement)
        this.calculateAnimationElementsCenterPoints(svgElement)
        this.isAlreadyInlineSvg = true
        this.startElementAnimationsIfAnimationsActive()
      })
    },
    showPlayPauseButton() {
      return (
        this.isAlreadyInlineSvg &&
        this.hasAnimatedElements() &&
        !this.disablePlayPause
      )
    },
    hasAnimatedElements() {
      return this.animateElements.length > 0
    },
    calculateAnimationElementsCenterPoints(svgElement) {
      this.animateElements.forEach(animateElement => {
        const animatedSvgElements = document.querySelectorAll(
          animateElement.query
        )

        animatedSvgElements.forEach(animatedSvgElement => {
          // Only do this, if the user explictly set for this animation to programmatically calculate the center point of the element
          // that will be animated. Usually this is only needed if the animation rotates around around the center point
          if (animateElement.calculateElementCenterPoint) {
            const animatedSvgElementRect =
              animatedSvgElement.getBoundingClientRect()
            // By default, if you animate anything in an SVG, it will use the SVGs 0,0 as center point (especially interesting when
            // using animations with transform: rotate(42deg). Further on for animations within SVG the reference point generally is the
            // container of the SVG, not of the window. The SVG will not always be shown in the full size, depending on the browser window size
            // The following lines determine the position of the element to be animated within the page and calculate where it is placed
            // within the full size SVG. The center point of the element at this position is then set as the origin for any CSS transformation
            // operations
            // The rect of the SVG as it is rendered
            const svgRect = svgElement.getBoundingClientRect()
            // The rect of the SVG as it would be full size
            const svgBaseRect = svgElement.viewBox.baseVal
            // Calculate the offsets for the element on the vertical axis between the rendered SVG and the full size SVG
            const yOffset = (svgRect.height - svgBaseRect.height) / 2
            // Use window width for horizontal calculation. SVG is smaller than window width on displays > 1920px
            const xOffset = (window.innerWidth - svgBaseRect.width) / 2
            // Calculate the elements top left position in the full size SVG
            const xInBaseRect = animatedSvgElementRect.x - xOffset
            const yInBaseRect = animatedSvgElementRect.y - yOffset
            // Calcuate the elements center point in the full size SVG
            const xCenter = Math.round(
              xInBaseRect + animatedSvgElementRect.width / 2
            )
            const yCenter = Math.round(
              yInBaseRect + animatedSvgElementRect.height / 2
            )

            // Set the elements center point as origin for any CSS transformations
            animatedSvgElement.style[
              'transform-origin'
            ] = `${xCenter}px ${yCenter}px`
          } else {
            // In almost all other cases this CSS setting will exactly do what is needed
            animatedSvgElement.style['transform-box'] = 'fill-box'
          }
        })
      })
    },
    startElementAnimationsIfAnimationsActive() {
      if (this.animationActive) {
        this.startElementAnimations()
      }
    },
    startElementAnimations() {
      this.animateElements.forEach(animateElement => {
        const animatedSvgElements = document.querySelectorAll(
          animateElement.query
        )

        animatedSvgElements.forEach(animatedSvgElement => {
          if (animatedSvgElement.style['animation-play-state'] === 'paused') {
            animatedSvgElement.style['animation-play-state'] = 'running'

            return
          }

          const randomNumberRegexMatch =
            animateElement.animation.match(randomNumberRegex)

          if (randomNumberRegexMatch !== null) {
            const min = Number.parseInt(randomNumberRegexMatch[1])
            const max = Number.parseInt(randomNumberRegexMatch[2])
            const randomNumber = Math.random() * (max - min) + min
            const animationWithRandomDuration =
              animateElement.animation.replace(
                randomNumberRegex,
                randomNumber.toFixed(2)
              )

            animatedSvgElement.style['animation'] = animationWithRandomDuration
          } else {
            animatedSvgElement.style['animation'] = animateElement.animation
          }

          animatedSvgElement.style['animation-play-state'] = 'running'
        })
      })
    },
    stopElementAnimations() {
      this.animateElements.forEach(animateElement => {
        const animatedSvgElements = document.querySelectorAll(
          animateElement.query
        )

        animatedSvgElements.forEach(animatedSvgElement => {
          animatedSvgElement.style['animation-play-state'] = 'paused'
        })
      })
    },
    playPauseAnimation(event) {
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.animationActive = !this.animationActive
      if (this.animationActive) {
        this.startElementAnimations()
      } else {
        this.stopElementAnimations()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-svg-container {
  position: relative;

  .play-pause {
    cursor: pointer;
    position: absolute;
    right: 2em;
    bottom: 1em;
    background: rgba($c-secondary, 0.7);
    padding: 1px;
    border-radius: 50%;
    z-index: 9;
    line-height: 16px;

    @include breakpoint($up-to-desktop) {
      right: unset;
      left: 16px;
    }

    @include breakpoint($up-to-tablet) {
      right: unset;
      left: 16px;
      bottom: 72px;
    }

    :deep(svg) {
      fill: $root-color;
      filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg)
        brightness(104%) contrast(104%);

      &:hover {
        filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg)
          brightness(75%) contrast(104%);
      }
    }
  }
}

.inline-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.inline-svg-source {
  align-self: center;
  height: 100%;
  object-fit: contain;
}
</style>
